// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Models
import { SessionToken } from "../models/session-token.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class VideoInsuredService {
  private endpoint = environment.api_url;
  private token: string;

  constructor(private http: HttpClient) {}

  /**
   * * Set the current auth 'token'.
   */
  setAuthToken = (token: string) => {
    this.token = token;
  };

  /**
   * * Returns the current auth 'token'.
   */
  getAuthToken = () => this.token;

  /**
   * * Get sms data from API.
   * * Used by resolver.
   */
  getSmsInfo(): Promise<SessionToken> {
    const url = `${this.endpoint}/mobile/sessions/${this.token}`;
    console.log("url: ", `${this.endpoint}/mobile/sessions/${this.token}`);
    return this.http.get<SessionToken>(url).toPromise();
  }

  /**
   * * Request twilio room info.
   */
  connect = (sessionToken: SessionToken): Promise<{ token: string }> => {
    const url = `${this.endpoint}/mobile/sessions`;

    const obj = {
      teleExpertise: sessionToken,
      type: "tele-expert",
    };

    return this.http.post<{ token: string }>(url, obj).toPromise();
  };

  /**
   * Delete task.
   */
  deleteTask = (taskSid: string) => {
    const url = `${this.endpoint}/tasks/update/${taskSid}`;

    return this.http.post(url, {});
  };

  getPhotoAttachment = async (
    expertiseNumber: string,
    imageCapture: any
  ): Promise<any> => {
    const url = `${this.endpoint}/video-call/save-photo/${expertiseNumber}`;

    const blob = await imageCapture.takePhoto({ imageWidth: 600 });
    console.log("%cblob", "color: cyan", blob);

    const form = new FormData();

    form.append("attachment", blob);
    form.append("type", "image");

    console.log("%cForm Data", "color: cyan", form);

    return this.http.post<any>(url, form).toPromise();
  };

  getPdf = async (pdfName: string) => {
    const url = `${this.endpoint}/video/policy/${pdfName}`;

    return new Promise((resolve) => {
      this.http.get(url, { responseType: "blob" }).subscribe((data) => {
        const blob = new Blob([data as any], { type: "application/pdf" });
        const newUrl = window.URL.createObjectURL(blob);
        window.open(newUrl);
        resolve(newUrl);
      });
    });
  };
}
