import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VideoInsuredService {
    constructor(http) {
        this.http = http;
        this.endpoint = environment.api_url;
        /**
         * * Set the current auth 'token'.
         */
        this.setAuthToken = (token) => {
            this.token = token;
        };
        /**
         * * Returns the current auth 'token'.
         */
        this.getAuthToken = () => this.token;
        /**
         * * Request twilio room info.
         */
        this.connect = (sessionToken) => {
            const url = `${this.endpoint}/mobile/sessions`;
            const obj = {
                teleExpertise: sessionToken,
                type: "tele-expert",
            };
            return this.http.post(url, obj).toPromise();
        };
        /**
         * Delete task.
         */
        this.deleteTask = (taskSid) => {
            const url = `${this.endpoint}/tasks/update/${taskSid}`;
            return this.http.post(url, {});
        };
        this.getPhotoAttachment = (expertiseNumber, imageCapture) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${this.endpoint}/video-call/save-photo/${expertiseNumber}`;
            const blob = yield imageCapture.takePhoto({ imageWidth: 600 });
            console.log("%cblob", "color: cyan", blob);
            const form = new FormData();
            form.append("attachment", blob);
            form.append("type", "image");
            console.log("%cForm Data", "color: cyan", form);
            return this.http.post(url, form).toPromise();
        });
        this.getPdf = (pdfName) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${this.endpoint}/video/policy/${pdfName}`;
            return new Promise((resolve) => {
                this.http.get(url, { responseType: "blob" }).subscribe((data) => {
                    const blob = new Blob([data], { type: "application/pdf" });
                    const newUrl = window.URL.createObjectURL(blob);
                    window.open(newUrl);
                    resolve(newUrl);
                });
            });
        });
    }
    /**
     * * Get sms data from API.
     * * Used by resolver.
     */
    getSmsInfo() {
        const url = `${this.endpoint}/mobile/sessions/${this.token}`;
        console.log("url: ", `${this.endpoint}/mobile/sessions/${this.token}`);
        return this.http.get(url).toPromise();
    }
}
VideoInsuredService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoInsuredService_Factory() { return new VideoInsuredService(i0.ɵɵinject(i1.HttpClient)); }, token: VideoInsuredService, providedIn: "root" });
