// Socket
import * as io from 'socket.io-client';
// Environment
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class SocketIOService {
    constructor() {
        this.getSocket = () => this.socket;
        this.setupSocketConnection = () => {
            this.socket = io(environment.SOCKET_ENDPOINT, {
                path: environment.api_url + "/socket.io",
                transports: ["websocket"],
            });
        };
    }
}
SocketIOService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketIOService_Factory() { return new SocketIOService(); }, token: SocketIOService, providedIn: "root" });
