import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
// Rxjs
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
// Services
import { VideoInsuredService } from '../video-insured/services/video-insured.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../video-insured/services/video-insured.service";
export class HttpInterceptorService {
    constructor(router, videoInsuredService) {
        this.router = router;
        this.videoInsuredService = videoInsuredService;
    }
    intercept(req, next) {
        const authToken = this.videoInsuredService.getAuthToken();
        let tokenRequest;
        if (!req.url.includes('googleapis')) {
            tokenRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}` ? `Bearer ${authToken}` : `Bearer null`,
                },
            });
        }
        else {
            tokenRequest = req;
        }
        return next.handle(tokenRequest).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
            }
        }), catchError((err) => {
            if (err instanceof HttpErrorResponse) {
                try {
                    if (err.status === 410) {
                        console.log(`%cUnauthorized`, `color: red`);
                        this.router.navigateByUrl(`/unauthorized`);
                    }
                    if (err.status === 504 || err.status === 401 || (err.status === 400 && err.message === 'Invalid cookie value')) {
                    }
                }
                catch (e) {
                    console.log(err);
                }
            }
            return of(err);
        }));
    }
}
HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.VideoInsuredService)); }, token: HttpInterceptorService, providedIn: "root" });
